import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import axios from 'axios';

const Announcment = () => {
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const res = await axios.get("https://kidpedia.net/api/announcements/");
        setAnnouncements(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAnnouncements();
  }, []);

  if (announcements.length === 0) {
    return null;
  }

  return (
    <Swiper
      spaceBetween={10}
      slidesPerView={1}
      loop={true}
      autoplay={{ delay: 3000 }}
      modules={[Autoplay]}
    >
      {announcements.map((announcement, index) => (
        <SwiperSlide key={announcement.id}>
          <a href={announcement.link} target="_blank" rel="noopener noreferrer">
            <img 
              src={`https://kidpedia.net/upload/${announcement.image}`}
              alt={`Announcement ${index + 1}`}
              style={{ width: '100%', height: '50px', objectFit: 'contain' }}
            />
          </a>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Announcment;
