import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Container, Row, Col, Form, Button, ListGroup, Badge, Card, Modal, Pagination } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { AuthContext } from "../context/authContext";
import { Link } from "react-router-dom";
import { RiAlertLine } from "react-icons/ri";
import { FaSpinner } from "react-icons/fa";

const Questions = () => {
  const { currentUser } = useContext(AuthContext);
  const [questions, setQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState({ category: "", question: "" });
  const [selectedCategory, setSelectedCategory] = useState("");
  const [answers, setAnswers] = useState({});
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const [votedQuestions, setVotedQuestions] = useState({});
  const [editingQuestionId, setEditingQuestionId] = useState(null);
  const [editingQuestionText, setEditingQuestionText] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [questionToDelete, setQuestionToDelete] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const questionsPerPage = 7;
  const [isAnonymous, setIsAnonymous] = useState(false);


  useEffect(() => {
    fetchQuestions();
    const timer = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  const categories = ["Akademik", "Erken Çocukluk", "Aile", "Gelişimsel Aksamalar", "Davranış-Uyum", "Öğrenme Süreçleri"];
  const handleSubmitQuestion = (e) => {
    e.preventDefault(); // Sayfa yenilemeyi önle
    if (!newQuestion.category || !newQuestion.question) {
      alert("Lütfen bir kategori seçin ve bir soru yazın");
      return;
    }

    const questionData = {
      ...newQuestion,
      isAnonymous: isAnonymous
    };

    if (isUpdateMode) {
      updateQuestion(e);
    } else {
      axios
        .post(`https://kidpedia.net/api/questions`, questionData)
        .then((res) => {
          setQuestions((prev) => [...prev, res.data]);
          setFilteredQuestions((prev) => [...prev, res.data]);
          setNewQuestion({ category: "", question: "" }); // Formu sıfırla
          setIsAnonymous(false); // Reset anonymous checkbox
          fetchQuestions(); // Fetch questions after adding a new one
        });
    }
  };

  const fetchQuestions = () => {
    axios.get(`https://kidpedia.net/api/questions`).then((res) => {
      const sortedQuestions = res.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setQuestions(sortedQuestions);
      setFilteredQuestions(sortedQuestions);
    });
  };

  const filterQuestions = (category) => {
    setSelectedCategory(category);
    if (category === "") {
      setFilteredQuestions(questions);
    } else {
      const filtered = questions.filter((q) => q.category === category);
      setFilteredQuestions(filtered);
    }
    setCurrentPage(1); // Reset to first page on filter change
  };

  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions = filteredQuestions.slice(indexOfFirstQuestion, indexOfLastQuestion);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredQuestions.length / questionsPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const voteQuestion = (id, type) => {
    if (!currentUser) {
      toast.error("Oy kullanmak için giriş yapmalısınız.");
      return;
    }

    const voteKey = `voted_${id}`;
    const lastVoteTime = localStorage.getItem(voteKey);
    const currentTime = Date.now().toString();
    localStorage.setItem(voteKey, currentTime);
    console.log(`Vote time for question ${id} set to ${currentTime}`);

    if (lastVoteTime && (Date.now() - parseInt(lastVoteTime, 10)) < 10800000) { // 3 hours in milliseconds
      toast.error("Bu soruya tekrar oy veremezsiniz.");
      return;
    }

    setVotedQuestions((prev) => ({ ...prev, [id]: true }));
    setQuestions((prev) =>
      prev.map((q) =>
        q.id === id
          ? { ...q, votes: type === "up" ? q.votes + 1 : q.votes - 1 }
          : q
      )
    );

    axios
      .post(`https://kidpedia.net/api/questions/${id}/vote`, { vote: type })
      .then((response) => {
        if (response.data.message !== "Vote recorded") {
          setVotedQuestions((prev) => ({ ...prev, [id]: false }));
          fetchQuestions();
        } 
      })
      .catch((error) => {
        console.error("Oy verirken hata oluştu:", error);
        setVotedQuestions((prev) => ({ ...prev, [id]: false }));
        fetchQuestions();
      });
  };

  const addAnswer = (questionId, answer) => {
    if (!answer.trim()) return;
    axios
      .post(`https://kidpedia.net/api/questions/${questionId}/answers`, { answer }) // Düzeltildi
      .then(() => {
        setAnswers((prev) => ({
          ...prev,
          [questionId]: "",
        }));
        fetchQuestions(); // Cevap ekledikten sonra soruları yeniden getir
      })
      .catch((error) => {
        console.error("Cevap eklerken hata oluştu:", error);
      });
  };

  const handleDeleteQuestion = (id) => {
    setQuestionToDelete(id);
    setShowDeleteModal(true);
  };

  const confirmDeleteQuestion = () => {
    if (currentUser.role !== "admin") {
      toast.error("Bu işlemi gerçekleştirmek için yetkiniz yok.");
      return;
    }
    axios
      .delete(`https://kidpedia.net/api/questions/${questionToDelete}`)
      .then(() => {
        fetchQuestions();
        setShowDeleteModal(false);
        setQuestionToDelete(null);
      });
  };

  const handleEditQuestion = (question) => {
    setEditingQuestionId(question.id);
    setEditingQuestionText(question.question);
  };

  const saveEditedQuestion = (id) => {
    if (!editingQuestionText.trim()) {
      alert("Lütfen bir soru yazın");
      return;
    }
    axios
      .put(`https://kidpedia.net/api/questions/${id}`, { question: editingQuestionText, category: questions.find(q => q.id === id).category })
      .then(() => {
        fetchQuestions();
        setEditingQuestionId(null);
        setEditingQuestionText("");
      });
  };

  const updateQuestion = (e) => {
    e.preventDefault();
    if (!newQuestion.category || !newQuestion.question) {
      alert("Lütfen bir kategori seçin ve bir soru yazın");
      return;
    }
    axios
      .put(`https://kidpedia.net/api/questions/${currentQuestionId}`, newQuestion)
      .then(() => {
        fetchQuestions();
        setIsUpdateMode(false);
        setNewQuestion({ category: "", question: "" });
      });
  };

  return (
    <Container className="mt-5">
      <ToastContainer/>
      <Row>
        <Col md={3}>
          <h3>Kategoriler</h3>
          <ListGroup>
            <ListGroup.Item
              action
              active={selectedCategory === ""}
              onClick={() => filterQuestions("")}
            >
              Tüm Kategoriler
            </ListGroup.Item>
            {categories.map((cat) => (
              <ListGroup.Item
                key={cat}
                action
                active={selectedCategory === cat}
                onClick={() => filterQuestions(cat)}
              >
                {cat}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>

        <Col md={9}>
          <h3>Sorular</h3>
          {currentUser ? (
            <Form className="mb-4" onSubmit={handleSubmitQuestion}>
              <Form.Group>
                <Form.Label>Kategori</Form.Label>
                <Form.Select
                  value={newQuestion.category}
                  onChange={(e) =>
                    setNewQuestion((prev) => ({
                      ...prev,
                      category: e.target.value,
                    }))
                  }
                >
                  <option value="">Bir kategori seçin</option>
                  {categories.map((cat) => (
                    <option key={cat} value={cat}>
                      {cat}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Label>Soru</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Sorunuzu yazın"
                  value={newQuestion.question}
                  onChange={(e) =>
                    setNewQuestion((prev) => ({
                      ...prev,
                      question: e.target.value,
                    }))
                  }
                />
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Check
                  type="checkbox"
                  label="İsmim gizli kalsın"
                  checked={isAnonymous}
                  onChange={(e) => setIsAnonymous(e.target.checked)}
                />
              </Form.Group>
              <Button className="mt-3" type="submit">
                {isUpdateMode ? "Soruyu Güncelle" : "Soru Ekle"}
              </Button>
              {isUpdateMode && (
                <Button
                  variant="secondary"
                  className="mt-3 ms-2"
                  onClick={() => {
                    setIsUpdateMode(false);
                    setNewQuestion({ category: "", question: "" });
                  }}
                >
                  Güncellemeyi İptal Et
                </Button>
              )}
            </Form>
          ) : (
            <Card className="mb-3">
            <Card.Body className="d-flex flex-column align-items-center justify-content-center text-center">
              <div>
                <Card.Title className="text-black d-flex align-items-center justify-content-center mb-3">
                  <RiAlertLine className="me-2 text-danger " style={{ fontSize: "2.5rem" }} /> Lütfen soru sormak için giriş yapınız ya da kaydolunuz.
                </Card.Title>
                <div className="d-flex justify-content-center">
                  <Button as={Link} to="/login" variant="primary" className="me-2">
                    Giriş Yap
                  </Button>
                  <Button as={Link} to="/register" variant="secondary">
                    Kaydol
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
          
          )}

          <ListGroup>
            {loading ? (
              <ListGroup.Item className="d-flex align-items-center justify-content-center">
                <FaSpinner className="me-2" style={{ fontSize: "1.5rem" }} spin /> Sorular yükleniyor...
              </ListGroup.Item>
            ) : currentQuestions.length === 0 ? (
              <ListGroup.Item>Mevcut soru yok</ListGroup.Item>
            ) : (
              currentQuestions.map((q) => (
                <Card className="mb-3" key={q.id}>
                  <Card.Body>
                    <Card.Text>
                      <strong>{q.category}: </strong> 
                      {editingQuestionId === q.id ? (
                        <Form.Control
                          type="text"
                          value={editingQuestionText}
                          onChange={(e) => setEditingQuestionText(e.target.value)}
                        />
                      ) : (
                        <Link 
                          to={`/sorucevap/${q.id}`}
                          style={{ color: 'inherit', textDecoration: 'underline' }}
                          >
                          {q.question}
                        </Link>
                      )}
                    </Card.Text>
                    <Card.Subtitle className="mb-2 text-muted">
                      Soruyu soran: {q.user} - {new Date(q.created_at).toLocaleDateString()}
                    </Card.Subtitle>
                    <div>
                      <Button
                        variant="success"
                        size="sm"
                        onClick={() => voteQuestion(q.id, "up")}
                        disabled={votedQuestions[q.id]}
                      >
                        👍
                      </Button>{" "}
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => voteQuestion(q.id, "down")}
                        disabled={votedQuestions[q.id]}
                      >
                        👎
                      </Button>{" "}
                      <Badge bg="info">{q.votes}</Badge>
                    </div>

                    <div className="mt-3">
                      <h6>Cevaplar:</h6>
                      <ListGroup>
                        {q.answers && q.answers.length > 0 ? (
                          q.answers.map((answerObj, index) => (
                            <ListGroup.Item key={index}>
                              {answerObj.answer}
                            </ListGroup.Item>
                          ))
                        ) : (
                          <ListGroup.Item>
                            Yöneticilerimiz En Kısa Sürede Cevap Verecektir. Cevap verildiğinde hem buradan hemde mail adresinden cevabınızı görüntüleyebilirsiniz. Lütfen spam (gereksiz) kutusu dahil mail adresinizi kontrol ediniz.
                          </ListGroup.Item>
                        )}
                      </ListGroup>
                      {currentUser && currentUser.role === "admin" && (
                        <Form.Group className="mt-2">
                          <Form.Control
                            type="text"
                            placeholder="Cevabınızı yazın"
                            value={answers[q.id] || ""}
                            onChange={(e) =>
                              setAnswers((prev) => ({
                                ...prev,
                                [q.id]: e.target.value,
                              }))
                            }
                          />
                          <Button
                            className="mt-2"
                            size="sm"
                            onClick={() => addAnswer(q.id, answers[q.id])}
                          >
                            Cevap Ekle
                          </Button>
                        </Form.Group>
                      )}
                    </div>
                    {currentUser && currentUser.role === "admin" && (
                      <>
                        {editingQuestionId === q.id ? (
                          <>
                            <Button
                              variant="primary"
                              size="sm"
                              className="mt-2 me-2"
                              onClick={() => saveEditedQuestion(q.id)}
                            >
                              Kaydet
                            </Button>
                            <Button
                              variant="secondary"
                              size="sm"
                              className="mt-2"
                              onClick={() => setEditingQuestionId(null)}
                            >
                              İptal
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              variant="primary"
                              size="sm"
                              className="mt-2 me-2"
                              onClick={() => handleEditQuestion(q)}
                            >
                              Soruyu Düzenle
                            </Button>
                            <Button
                              variant="danger"
                              size="sm"
                              className="mt-2"
                              onClick={() => handleDeleteQuestion(q.id)}
                            >
                              Soruyu Sil
                            </Button>
                          </>
                        )}
                      </>
                    )}
                  </Card.Body>
                </Card>
              ))
            )}
          </ListGroup>

          <nav>
            <Pagination>
              {pageNumbers.map(number => (
                <Pagination.Item
                  key={number}
                  onClick={() => paginate(number)}
                  active={currentPage === number}
                >
                  {number}
                </Pagination.Item>
              ))}
            </Pagination>
          </nav>
        </Col>
      </Row>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Onay</Modal.Title>
        </Modal.Header>
        <Modal.Body>Bu soruyu silmek istediğinize emin misiniz?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Hayır
          </Button>
          <Button variant="danger" onClick={confirmDeleteQuestion}>
            Evet
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Questions;
