import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Card, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import dyslexia from "../asset/dyslexia.png";
import gelisim from "../asset/baby-icon.svg";
import autism from "../asset/autism.png";
import { FaBookReader } from "react-icons/fa";
import egitim from "../asset/portrait-child-school.jpg";
import { Style } from "react-style-tag";
import Bannerimg from "../asset/041.png";
import "./StyleBody.scss";
import { RiCalculatorLine } from "react-icons/ri";
import zihin from "../asset/ZYetersizlgi.png";
import Dil from "../asset/Dil.png";
import { FaChild } from "react-icons/fa";
import { MdFamilyRestroom } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react"; // Import Swiper React components
import "swiper/swiper-bundle.css"; // Import Swiper styles
import { Navigation, Pagination, Mousewheel } from "swiper/modules"; // Import Swiper modules
import LatestQuestions from "./LatestQuestions";
import Announcment from "./Announcment";

const Body = () => {
  const [posts, setPosts] = useState([]);
  const [events, setEvents] = useState([]); // Add this new state

  useEffect(() => {
    fetch("https://kidpedia.net/api/posts/")
      .then((response) => response.json())
      .then((data) => {
        const limitedPosts = data.slice(0, 20).map((post) => ({
          ...post,
          img: `https://kidpedia.net/upload/${post.img}`,
        }));
        setPosts(limitedPosts);
      });
  }, []);

  // Add this new useEffect for events
  useEffect(() => {
    fetch("https://kidpedia.net/api/events")
      .then((response) => response.json())
      .then((data) => {
        const limitedEvents = data.slice(0, 10);
        setEvents(limitedEvents);
      });
  }, []);

  useEffect(() => {
    if (window.innerWidth > 992) {
      // Only add dummy bullet on desktop
      const dummyBullet = document.createElement("span");
      dummyBullet.className = "swiper-pagination-bullet bg-danger text-white";
      dummyBullet.setAttribute("data-bullet", "T");
      dummyBullet.innerText = "T";
      dummyBullet.onclick = () => {
        window.location.href = "/posts";
      };
      const paginationContainer = document.querySelector(".swiper-pagination");
      if (paginationContainer) {
        paginationContainer.appendChild(dummyBullet);
      }
    }
  }, [posts]);

  useEffect(() => {
    // Only target bullets within the posts slider having class "numbered-pagination"
    const paginationBullets = document.querySelectorAll(
      ".numbered-pagination .swiper-pagination-bullet"
    );
    paginationBullets.forEach((bullet, index) => {
      if (bullet.innerText !== "T") {
        if (window.innerWidth > 992) {
          bullet.innerText = index + 1; // Set numbers for posts swiper only
        } else {
          bullet.innerText = ""; // Hide bullet text on small screens
        }
        bullet.addEventListener("mouseenter", () => {
          bullet.click();
        });
      }
    });
  }, [posts]);

  const handleSlideChange = (swiper) => {
    const prevArrow = document.querySelector(".swiper-button-prev");
    const nextArrow = document.querySelector(".swiper-button-next");

    if (swiper.activeIndex === 0) {
      prevArrow.style.display = "none";
    } else {
      prevArrow.style.display = "block";
    }

    if (swiper.activeIndex === swiper.slides.length - 1) {
      nextArrow.style.display = "none";
    } else {
      nextArrow.style.display = "block";
    }
  };

  return (
    <>
      <Container className="p-2">
        {/* Directly render Announcment; it returns null when no data is available */}
        <Announcment />
        <Row className="mb-1 mt-1">
          <Col lg={8}>
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              navigation
              pagination={{
                clickable: true,
                renderBullet: (index, className) => {
                  return `<span class="${className}">${
                    window.innerWidth > 992 ? index + 1 : ""
                  }</span>`;
                },
              }}
              modules={[Navigation, Pagination, Mousewheel]}
              className="numbered-pagination"
              style={{
                paddingBottom: window.innerWidth > 992 ? "50px" : "25px",
              }}
              onSlideChange={handleSlideChange}
              onSwiper={handleSlideChange}
            >
              {posts.map((post) => (
                <SwiperSlide key={post.id}>
                  <Link
                    to={`/posts/${post.cat}/${post.id}/${post.title}`}
                    style={{ textDecoration: "none" }}
                  >
                    <div style={{ position: "relative" }}>
                      <img
                        src={post.img}
                        alt={post.title}
                        style={{
                          width: "100%",
                          height: "auto",
                          aspectRatio: "2 / 1",
                          objectFit: "cover",
                        }}
                      />
                      <h5
                        className="text-center"
                        style={{
                          position: "absolute",
                          bottom: "0",
                          left: "0",
                          right: "0",
                          color: "white",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          margin: "0",
                          padding: "10px",
                        }}
                      >
                        {post.title}
                      </h5>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </Col>
          <Col lg={4}>
            <Swiper
              direction={window.innerWidth > 992 ? "vertical" : "horizontal"} // Set direction to vertical on desktop and horizontal on mobile
              spaceBetween={15}
              slidesPerView={window.innerWidth > 992 ? 3 : 2} // Show 2 slides per view on mobile
              navigation
              pagination={{
                clickable: true,
                renderBullet: (index, className) => {
                  return `<span class="${className}"></span>`; // Hide numbers on bullets
                },
              }}
              modules={[Navigation, Pagination, Mousewheel]}
              className="vertical-swiper"
              style={{
                height: window.innerWidth > 992 ? "400px" : "auto", // Adjust height for mobile
                paddingRight: window.innerWidth > 992 ? "30px" : "0", // Adjust padding for mobile
              }}
            >
              {events.map((event) => (
                <SwiperSlide key={event.id}>
                  <Link
                    to={`/events/${event.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "120px",
                        borderRadius: "8px",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={`https://kidpedia.net/upload/${event.img}`}
                        alt={event.title}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover", // Ensure images cover the container
                          aspectRatio: "2 / 1", // Maintain 2:1 aspect ratio
                        }}
                      />
                      <h6
                        className="text-center"
                        style={{
                          fontSize:
                            window.innerWidth <= 992 ? "0.8rem" : undefined, // Adjust font size for mobile
                          position: "absolute",
                          bottom: "0",
                          left: "0",
                          right: "0",
                          color: "white",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          margin: "0",
                          padding: "10px",
                        }}
                      >
                        {event.title}
                      </h6>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
              {/* Add dummy slide */}
              <SwiperSlide>
                <Link to="/etkinlikler" style={{ textDecoration: "none" }}>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "120px",
                      borderRadius: "8px",
                      overflow: "hidden",
                      backgroundColor: "#007bff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      aspectRatio: "2 / 1", // Maintain 2:1 aspect ratio
                    }}
                  >
                    <h6
                      style={{
                        margin: 0,
                        color: "white",
                        fontSize: "1.1rem",
                        fontWeight: "bold",
                        textShadow: "1px 1px 2px rgba(0,0,0,0.8)",
                      }}
                    >
                      Tümünü Gör
                    </h6>
                  </div>
                </Link>
              </SwiperSlide>
            </Swiper>
          </Col>
        </Row>
        <Row className="mb-2 mt-2">
          <Col sm={12}>
            <div
              className="slogan-container p-4 text-center rounded shadow-sm"
              style={{
                border: "1px solid rgba(0,0,0,0.1)",
              }}
            >
              <h3
                className="main-slogan mb-2"
                style={{
                  color: "#2c3e50",
                  fontWeight: "700",
                }}
              >
                Gelişime Açılan Kapı{" "}
                <span style={{ color: "#e74c3c" }}>ÜCRETSİZ!</span>
              </h3>
              <h4
                className="sub-slogan"
                style={{
                  color: "#34495e",
                  fontWeight: "500",
                }}
              >
                Sor, Öğren, Uygula
              </h4>
            </div>
          </Col>
        </Row>

        <Row className="mb-1 mt-1">
          <Col sm={12}>
            <h5 className="mb-3">Son Cevaplanan Sorular</h5>
            <LatestQuestions />
          </Col>
        </Row>
      </Container>
      <Container className="mt-1 ">
        <Row>
          {" "}
          <h2 className="mt-1 font-weight-bolder text-center opacity-75">
            {" "}
            Hizmet Sağladığımız Alanlar
          </h2>
          <h6 className="font-weight-bolder text-center opacity-75">
            {" "}
            Daha fazla bilgi için kutucuklara tıklayın.{" "}
          </h6>{" "}
        </Row>
      </Container>

      {/* Kartlar kısmının başlangıcı */}
      <Container>
        <Row>
          <Col sm={4} className="d-flex flex-column">
            <Card
              className="card align-items-center flex-grow-1"
              style={{ width: "auto", color: "black" }}
            >
              <div
                style={{
                  width: "7rem",
                  height: "7rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FaBookReader size={70} color="#000" />
              </div>
              <Card.Body>
                <Card.Title className="card align-items-center fw-bolder text-center ">
                  Akademik{" "}
                </Card.Title>
                <Card.Text>
                  <em>Türkçe</em> / <em>Matematik</em> /{" "}
                  <em>Diğer Akademik Alanlar </em>
                </Card.Text>

                <Col>
                  <Button
                    className="ms-4"
                    variant="success"
                    href="/posts/akademik"
                  >
                    Makaleler
                  </Button>
                  <Button className="ms-4" variant="warning" href="/etkinlikler/akademik">
                    Etkinlikler{" "}
                  </Button>
                </Col>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={4} className="d-flex flex-column">
            <Card
              className="card align-items-center flex-grow-1"
              style={{ width: "auto", color: "black" }}
            >
              <Card.Img
                variant="top"
                src={gelisim}
                style={{ width: "7rem", height: "7rem" }}
              />
              <Card.Body>
                <Card.Title className="card align-items-center fw-bolder text-center ">
                  Erken Çocukluk{" "}
                </Card.Title>
                <Card.Text>
                  <em>Beslenme Süreci</em> / <em>Emzirme Süreci</em> /{" "}
                  <em>Tuvalet Eğitimi </em>
                </Card.Text>

                <Col>
                  <Button
                    className="ms-4"
                    variant="success"
                    href="/posts/erkencocukluk"
                  >
                    Makaleler
                  </Button>
                  <Button
                    className="ms-4"
                    variant="warning"
                    href="/etkinlikler/erkencocukluk"
                  >
                    Etkinlikler{" "}
                  </Button>
                </Col>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={4} className="d-flex flex-column">
            <Card
              className="card align-items-center flex-grow-1"
              style={{ width: "auto", color: "black" }}
            >
              <MdFamilyRestroom
                style={{ height: "10rem", width: "20rem", color: "black" }}
              />
              <Card.Body>
                <Card.Title className="card align-items-center fw-bolder fs-3 text-center">
                  Aile Eğitimi
                </Card.Title>

                <Col>
                  <Button
                    className="ms-4"
                    variant="success"
                    href="/posts/aileegitimi"
                  >
                    Makaleler
                  </Button>
                  <Button
                    className="ms-4"
                    variant="warning"
                    href="/etkinlikler/aileegitimi"
                  >
                    Etkinlikler{" "}
                  </Button>
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* ikinci Bölüm */}
        <Row className="mt-5">
          <Col sm={4} className="d-flex flex-column">
            <Card
              className="card align-items-center flex-grow-1"
              style={{ width: "auto", color: "black" }}
            >
              <Card.Img
                variant="top"
                src={dyslexia}
                style={{ width: "10rem", height: "10rem" }}
              />
              <Card.Body>
                <Card.Title className="card align-items-center fw-bolder fs-3 text-center">
                  Gelişimsel Aksamalar
                </Card.Title>

                <Col>
                  <Button
                    className="ms-4"
                    variant="success"
                    href="/posts/gelisimselaksamalar"
                  >
                    Makaleler
                  </Button>
                  <Button
                    className="ms-4"
                    variant="warning"
                    href="/etkinlikler/gelisimselaksamalar"
                  >
                    Etkinlikler
                  </Button>
                </Col>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={4} className="d-flex flex-column">
            <Card
              className="card align-items-center flex-grow-1"
              style={{ width: "auto", color: "black" }}
            >
              <Card.Img variant="top" src={Dil} style={{ width: "10rem" }} />
              <Card.Body>
                <Card.Title
                  className="card align-items-center fw-bolder"
                  style={{ fontSize: "1.4rem", textAlign: "center" }}
                >
                  Davranış, Uyum ve İletişim Problemleri
                </Card.Title>

                <Col>
                  <Button
                    className="ms-4"
                    variant="success"
                    href="/posts/davranisproblemleri"
                  >
                    Makaleler
                  </Button>
                  <Button
                    className="ms-4"
                    variant="warning"
                    href="/etkinlikler/davranisproblemleri"
                  >
                    Etkinlikler{" "}
                  </Button>
                </Col>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={4} className="d-flex flex-column">
            <Card
              className="card align-items-center flex-grow-1"
              style={{ width: "auto", color: "black" }}
            >
              <Card.Img variant="top" src={autism} style={{ width: "10rem" }} />
              <Card.Body>
                <Card.Title className="card align-items-center fw-bolder fs-3 text-center">
                  Öğrenme Sorunları
                </Card.Title>

                <Col>
                  <Button
                    className="ms-4"
                    variant="success"
                    href="/posts/ogrenmesorunlari"
                  >
                    Makaleler
                  </Button>
                  <Button
                    className="ms-4"
                    variant="warning"
                    href="/etkinlikler/ogrenmesorunlari"
                  >
                    Etkinlikler{" "}
                  </Button>
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Üçüncü kartlar bölümü */}
      </Container>

      {/* Bilgi verme bölümü */}

      {/*       <Container className="mt-5">
        <Row className="mb-5 border-bottom ">
          {" "}
          <h2 className="font-weight-bolder text-center opacity-75">
            {" "}
            Hizmet Sürecimiz{" "}
          </h2>
        </Row>
      </Container>

      <Container className="mt-5">
        <Row
          className="rounded"
          style={{
            background: "rgb(218,218,218)",
            background:
              "linear-gradient(90deg, rgba(218,218,218,0.5410539215686274) 3%, rgba(233,233,233,1) 53%, rgba(0,0,0,0) 91%)",
          }}
        >
          <Col sm={7}>
            <h2 className="text-center opacity-75 fw-bolder ">
              {" "}
              Gelişim Desteği
            </h2>
            <ul className="fs-5">
              <li>
                Uzmanlarımız tarafından uygun ölçek uygulanarak, seviye tespit
                edilir.{" "}
              </li>
              <ul className="border-bottom border-dark">
                <li>Agte: Gelişim düzeyi ölçeği</li>
                <li>Metropolitan okula hazırlık</li>
                <li>İhtiyaca yönelik birçok ölçek</li>
              </ul>
              <li className="border-bottom border-dark">
                Uzmanlarımız tarafından uygun, bilimsel dayanaklı öneriler
                sunulur.
              </li>
              <li className="border-bottom  border-dark">
                Yapılan uygulamalar değerlendirilerek yol haritası çıkarılır.
              </li>
              <li className="border-bottom border-dark ">
                Tüm uygulamalar ve süreç, kayıt altına alınarak ilerlemeler
                gözlemlenir.
              </li>
            </ul>
          </Col>
          <Col sm={5} className="p-4 d-flex flex-wrap align-items-center">
            {" "}
            <Image className="rounded-3 img-fluid" src={egitim}></Image>
          </Col>
        </Row>
      </Container> */}
    </>
  );
};

export default Body;
