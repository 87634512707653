import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { RiCalculatorLine } from "react-icons/ri";
import zihin from "../asset/ZYetersizlgi.png";
import Dil from "../asset/Dil.png";
import dyslexia from "../asset/dyslexia.png";
import gelisim from "../asset/baby-icon.svg";
import autism from "../asset/autism.png";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { FaBookReader } from "react-icons/fa";
import {MdFamilyRestroom} from "react-icons/md";

const ArticleSidebar = ({ children }) => {
  const menuItem = [
    {
      path: "/posts/",
      name: "Tümü",
    },
    {
      path: "/posts/akademik",
      name: "Akademik",
      icon: (
        <FaBookReader style={{ height: "2rem", width: "2rem", color: "black" }} />
      ),
    },
    {
      path: "/posts/erkencocukluk",
      name: "Erken Çocukluk",
      icon: (
        <img src={gelisim} alt="" style={{ height: "1.8rem", width: "1.8rem", color: "black" }} />

      ),
    },
    {
      path: "/posts/aileegitimi",
      name: "Aile Eğitimi",
      icon: (
        <MdFamilyRestroom style={{ height: "2rem", width: "2rem", color: "black" }} />
      ),
    },
    {
      path: "/posts/gelisimselaksamalar",
      name: "Gelişimsel Aksamalar",
      icon: (
        <img src={dyslexia} alt="" style={{ height: "2rem", width: "2rem", color: "black" }} />
      ),
    },
    {
      path: "/posts/davranisproblemleri",
      name: "Davranış, Uyum ve İletişim Problemleri",
      icon: (
        <img src={Dil} alt="" style={{ height: "2rem", width: "2rem", color: "black" }} />
      ),
    },
    {
      path: "/posts/ogrenmesorunları",
      name: "Öğrenme Sorunları",
      icon: (
        <img src={autism} alt="" style={{ height: "2rem", width: "2rem", color: "black" }} />
      ),
    }
  ];

  const [posts, setPosts] = useState([]);

  const cat = useLocation().search;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/posts${cat}`);
        setPosts(res.data);
      } catch (err) {}
    };
    fetchData();
  }, [cat]);

  return (
    <div className="container">
      {menuItem.map((item, index) => (
        //    Buradaki end ile tüm linklerin active olmasının önüne geçiyoruz.
        <NavLink
          end
          to={item.path}
          key={index}
          className="link"
          activeclassName="active"
        >
          <div className="icon">{item.icon}</div>
          <div
            style={{ width: "100%", display: "table", textAlign: "center" }}
            className="link_text"
          >
            {item.name}
          </div>
        </NavLink>
      ))}
    </div>
  );
};

export default ArticleSidebar;
