import React, { useState, useContext } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import { AuthContext } from "../context/authContext";
import moment from "moment";
import AdminHeader from "../components/Admin/AdminHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EventWrite = () => {
  const { currentUser } = useContext(AuthContext);
  const defaultUser = `${currentUser.firstname} ${currentUser.lastname}`;

  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);
  const [cat, setCat] = useState("");
  const [publishInMagazine, setPublishInMagazine] = useState(1); // Default value is 1
  const [makale, setMakale] = useState(1); // Default value is 1
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken"); // Retrieve access token from localStorage
  const [authorName, setAuthorName] = useState(defaultUser);
  const [authorTitle, setAuthorTitle] = useState("");
  const [month, setMonth] = useState("");
  const [pdfFile, setPdfFile] = useState(null); // State for PDF file

  const upload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await axios.post(`https://kidpedia.net/api/upload`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Include access token in headers
        },
      });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const uploadPdf = async () => {
    try {
      const formData = new FormData();
      formData.append("pdf", pdfFile); // Assuming pdfFile is the selected PDF file
      const res = await axios.post("https://kidpedia.net/api/upload-pdf", formData);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();

    // Add validation
    if (!title || !value) {
      toast.error("Başlık ve içerik alanları zorunludur!");
      return;
    }

    if (!cat) {
      toast.error("Kategori seçilmeden kaydedilemez!");
      return;
    }

    try {
      // Log the content before upload
      console.log("Editor content:", value);
      
      const fileUrl = file ? await upload(file) : "";
      const pdfUrl = pdfFile ? await uploadPdf(pdfFile) : "";
      console.log(pdfUrl);
      console.log(fileUrl);

      const postData = {
        title,
        desc: value,
        cat,
        img:fileUrl,
        date: moment(Date.now()).format("YSYYY-MM-DD HH:mm:ss"),
        uid: currentUser.ID,
        ucad: currentUser.cat,
        authorName,
        authorTitle,
        month,
        pdfurls: pdfUrl,
      };

      // Log the full payload
      console.log("Sending data:", postData);

      const response = await axios.post(`https://kidpedia.net/api/events/`, postData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      // Log the response
      console.log("Server response:", response.data);

      if (response.data) {
        toast.success("Veri başarıyla kaydedildi!");
        setTimeout(() => {
          navigate("/admin/");
        }, 5000); // 5 seconds delay
      }
    } catch (err) {
      console.error("Error details:", err.response?.data || err.message);
      alert("Kayıt sırasında bir hata oluştu: " + (err.response?.data?.message || err.message));
    }
  };

  return (
    <>
      <AdminHeader />
      <Container>
        <Form>
          <Row>
            <Col sm={9}>
              <input
                type="text"
                placeholder="Başlık"
                className="form-control"
                onChange={(e) => setTitle(e.target.value)}
              />

              <ReactQuill
                theme="snow"
                value={value}
                onChange={setValue}
                className="h-75"
                style={{ minHeight: '300px', marginBottom: '50px' }}
              />
            </Col>

            <Col sm={3}>
              <div className="menu">
                <div className="item">
                  <h4>Yayınla</h4>
                  
                  <Form.Group
                    controlId="formFile"
                    className="mb-2"
                    id="file"
                    name=""
                    onChange={(e) => setFile(e.target.files[0])}
                  >
                    <Form.Label className="mt-2 text-end fw-bold">
                      Resim Yükle
                    </Form.Label>
                    <Form.Control type="file" />
                  </Form.Group>

                  <Form.Group
                    controlId="formPdf"
                    className="mb-2"
                    id="pdfFile"
                    name="pdfFile"
                    onChange={(e) => setPdfFile(e.target.files[0])}
                  >
                    <Form.Label className="mt-2 text-end fw-bold">
                      PDF Yükle
                    </Form.Label>
                    <Form.Control type="file" />
                  </Form.Group>

                  <div className="buttons">
                    <button onClick={handleClick}>Publish</button>
                  </div>
                </div>
                <div className="item">
                  <h4>Kategoriler</h4>
                  <div className="cat">
                    {[
                      "akademik",
                      "erkencocukluk",
                      "aileegitimi",
                      "gelisimselaksamalar",
                      "davranisproblemleri",
                      "ogrenmesorunlari",
                    ].map((category) => (
                      <div key={category}>
                        <input
                          type="radio"
                          name="cat"
                          value={category}
                          id={category}
                          onChange={(e) => setCat(e.target.value)}
                          checked={cat === category}
                        />
                        <label htmlFor={category}>{category}</label>
                      </div>
                    ))}
                  </div>
                </div>
                
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={9}>
              <input
                type="text"
                placeholder="Yazar Adı"
                className="form-control mt-2"
                value={authorName}
                onChange={(e) => {
                  setAuthorName(e.target.value);
                }}
              />

              <input
                type="text"
                placeholder="Yazar Ünvanı"
                className="form-control mt-2"
                value={authorTitle}
                onChange={(e) => setAuthorTitle(e.target.value)}
              />
              <input
                type="text"
                placeholder="Tarih Örn: Eylül 2023"
                className="form-control mt-2"
                onChange={(e) => setMonth(e.target.value)}
              />
            </Col>
          </Row>
        </Form>
      </Container>
      <ToastContainer />
    </>
  );
};

export default EventWrite;
