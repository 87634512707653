import { React, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Navbar,
  Container,
  Nav,
  NavbarBrand,
  NavLink,
  NavItem,
  Button,
  ButtonGroup,
} from "react-bootstrap";

const AdminHeader = () => {
  const location = useLocation();
  const [url, setUrl] = useState(null);
  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  return (
    <div>
      <Container>
        <Navbar bg="light" expand="md" className=" mb-4 border-bottom">
          <Nav>
          <NavItem>
              <NavLink className={url === "/admin/" ? " active" : ""} href="/admin/">
                {" "}
                AdminAnaSayfa
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={url === "/admin/write" ? " active" : ""} href="/admin/write">
                {" "}
                Makale Girişi
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={url === "/admin/etkinlikyaz" ? " active" : ""} href="/admin/etkinlikyaz">
                {" "}
                Etkinlik Girişi
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={url === "/admin/egitimyaz" ? " active" : ""} href="/admin/egitimyaz">
                {" "}
                Eğitim Girişi
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={url === "/admin/adminposts" ? " active" : ""} href="/admin/adminposts">
                {" "}
                Yayınlar
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={url === "/admin/adminposts" ? " active" : ""} href="/admin/duyuru">
                {" "}
                Duyuru
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={url === "/admin/egitim/" ? " active" : ""} href="/admin/egitim/">
                {" "}
                Eğitimler
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={url === "/admin/kisilereatananegitimler" ? " active" : ""} href="/admin/kisilereatananegitimler">
                {" "}
Kişlere Atanan Eğitim              </NavLink>
            </NavItem>
            <NavItem>
          <NavLink className={url ==="/admin/ucretsizgorusme" ? " active" : ""} href="/admin/ucretsizgorusme">
                {" "}
                Görüşme Linki
              </NavLink>
            </NavItem>
            <NavItem>
          <NavLink className={url ==="/admin/ucretligorusme" ? " active" : ""} href="/admin/ucretligorusme">
                {" "}
                Ücretli Görüşme Linki
              </NavLink>
            </NavItem>
            <NavItem>
          <NavLink className={url === "/admin/usercart" ? " active" : ""} href="/admin/usercart">
                {" "}
                Kullanıcı Sepeti
              </NavLink>
            </NavItem>
            <NavItem>
          <NavLink className={url === "/admin/iletisim" ? " active" : ""} href="/admin/iletisim">
                {" "}
iletisim              </NavLink>
            </NavItem>
          </Nav>
        </Navbar>
      </Container>
    </div>
  );
};

export default AdminHeader;