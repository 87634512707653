import React, { useState, useContext} from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import { AuthContext } from "../context/authContext";
import moment from "moment";
import AdminHeader from "../components/Admin/AdminHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Write = () => {
 
  const { currentUser } = useContext(AuthContext);
  const defaultUser = `${currentUser.firstname} ${currentUser.lastname}`;

  const state = useLocation().state;
  const [value, setValue] = useState(state?.title || "");
  const [title, setTitle] = useState(state?.desc || "");
  const [file, setFile] = useState(null);
  const [cat, setCat] = useState(state?.cat || "");
  const [publishInMagazine, setPublishInMagazine] = useState(1); // Default value is 1
  const [makale, setMakale] = useState(1); // Default value is 1
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken"); // Retrieve access token from localStorage
  const [authorName, setAuthorName] = useState(defaultUser);
  const [authorTitle, setAuthorTitle] = useState("");
  const [month, setMonth] = useState("");
  
  
  
  const upload = async () => {

    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await axios.post(`https://kidpedia.net/api/upload`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Include access token in headers
        },
      });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  //eğer dosya yüklenmemişse eski dosya linkini al


  const handleClick = async (e) => {
    e.preventDefault();

    // Add validation
    if (!title || !value) {
      toast.error("Başlık ve içerik alanları zorunludur!");
      return;
    }

    if (!cat) {
      toast.error("Kategori seçilmeden kaydedilemez!");
      return;
    }

    const fileUrl = await upload();

    try {
      state
        ? await axios.put(
            `https://kidpedia.net/api/posts/${state.id}`,
            {
              title,
              desc: value,
              cat,
              img: file ? fileUrl : "",
              date: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
              ucad: currentUser.cat,
              uid: currentUser.ID,
              publishInMagazine: publishInMagazine, // Include the checkbox state
              makaleyayinla: makale, // Include the checkbox state
              authorName,
              authorTitle,
              month,
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`, // Include access token in headers
              },
            }
          )
        : await axios.post(
            `https://kidpedia.net/api/posts/`,
            {
              title,
              desc: value,
              cat,
              img: file ? fileUrl : "",
              date: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
              ucad: currentUser.cat,
              uid: currentUser.ID,
              publishInMagazine: publishInMagazine, // Include the checkbox state
              makaleyayinla: makale, // Include the checkbox state
              authorName,
              authorTitle,
              month,
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`, // Include access token in headers
              },
            }
          );

      navigate("/posts");
    } catch (err) {
      console.log(err);
    }
  };

  // admin kodu bölümü
  switch (currentUser?.cat) {
    case 503450:
    case 503422:
      return (
        <>
          <AdminHeader />
          <Container>
            <Form>
              <Row>
                <Col sm={9}>
                  <input
                    type="text"
                    placeholder="Başlık"
                    className="form-control"
                    onChange={(e) => setTitle(e.target.value)}
                  />

                  <ReactQuill
                    theme="snow"
                    value={value}
                    onChange={setValue}
                    className="h-75 "
                  />
                </Col>

                <Col sm={3}>
                  <div className="menu">
                    <div className="item">
                      <h4>Yayınla</h4>
                      
                      <Form.Group
                        controlId="formFile"
                        className="mb-2"
                        id="file"
                        name=""
                        onChange={(e) => setFile(e.target.files[0])}
                      >
                        <Form.Label className="mt-2 text-end fw-bold">
                          Resim Yükle
                        </Form.Label>
                        <Form.Control type="file" />
                      </Form.Group>

                      <div className="buttons">
                        <button onClick={handleClick}>Publish</button>
                      </div>
                    </div>
                    <div className="item">
                      <h4>Kategoriler</h4>
                      <div className="cat">
                        {[
                          "akademik",
                          "erkencocukluk",
                          "aileegitimi",
                          "gelisimselaksamalar",
                          "davranisproblemleri",
                          "ogrenmesorunlari",
                        ].map((category) => (
                          <div key={category}>
                            <input
                              type="radio"
                              name="cat"
                              value={category}
                              id={category}
                              onChange={(e) => setCat(e.target.value)}
                              checked={cat === category}
                            />
                            <label htmlFor={category}>{category}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="item">
                      <h5>Dergide Yayınla</h5>
                      <input
                        type="checkbox"
                        defaultChecked={publishInMagazine === 1} // Use defaultChecked and compare with 1
                        onChange={(e) =>
                          setPublishInMagazine(e.target.checked ? 1 : 0)
                        } // Set 1 if checked, otherwise set 0
                        value={publishInMagazine}
                      />
                    </div>
                    <div className="item">
                      <h5>makaledelede Yayınla</h5>
                      <input
                        type="checkbox"
                        defaultChecked={makale === 1} // Use defaultChecked and compare with 1
                        onChange={(e) => setMakale(e.target.checked ? 1 : 0)} // Set 1 if checked, otherwise set 0
                        value={makale}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={9}>
                  <input
                    type="text"
                    placeholder="Yazar Adı"
                    className="form-control mt-2"
                    value={authorName}
                    onChange={(e) => {
                      setAuthorName(e.target.value);
                    }}
                  />

                  <input
                    type="text"
                    placeholder="Yazar Ünvanı"
                    className="form-control mt-2"
                    value={authorTitle}
                    onChange={(e) => setAuthorTitle(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Tarih Örn: Eylül 2023"
                    className="form-control mt-2"
                    onChange={(e) => setMonth(e.target.value)}
                  />
                </Col>
              </Row>
            </Form>
          </Container>
          <ToastContainer />
        </>
      );
      break;

    case 503439:
      return <div>BackendUser</div>;
      break;
    default:
      return <div>Bu sayfaya erişim hakkınız yok.</div>;
  }
};

export default Write;
