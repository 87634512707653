import React, { useState, useContext } from "react";
import {
  Form,
  Button,
  Col,
  Row,
  Modal,
  Card,
  ListGroup,
} from "react-bootstrap";
import axios from "axios";
import AdminHeader from "../components/Admin/AdminHeader";
import { AuthContext } from "../context/authContext";
import { RiDeleteBin7Fill } from "react-icons/ri";

const ManageAnnouncements = () => {
  const { currentUser } = useContext(AuthContext);
  const [announcements, setAnnouncements] = useState([]);
  const [newAnnouncement, setNewAnnouncement] = useState({
    image: "",
    link: "",
    isActive: true, // Changed default to true
  });
  const [file, setFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [showHeaderModal, setShowHeaderModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [announcementToDelete, setAnnouncementToDelete] = useState(null);
  const accessToken = localStorage.getItem("accessToken");

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewAnnouncement({
      ...newAnnouncement,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await axios.post(`https://kidpedia.net/api/upload`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAnnouncements = async () => {
    try {
      const res = await axios.get("https://kidpedia.net/api/announcements/all");
      setAnnouncements(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    fetchAnnouncements();
  }, []);

  const handleAddAnnouncement = async () => {
    if (!file && !newAnnouncement.text) {
      alert("Lütfen resim veya metin ekleyin!");
      return;
    }

    try {
      const fileUrl = file ? await upload() : "";
      await axios.post(
        "https://kidpedia.net/api/announcements",
        {
          image: fileUrl,
          link: newAnnouncement.link,
          isActive: newAnnouncement.isActive ? 1 : 0, // Ensure 0 or 1
          ucad: currentUser.cat,
          headerduyuru: newAnnouncement.text,
          isHeader: newAnnouncement.isHeader,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      fetchAnnouncements();
      setNewAnnouncement({ image: "", link: "", isActive: true, text: "", isHeader: false });
      setFile(null);
    } catch (err) {
      console.log(err);
    }
  };

  const handleToggleActive = (announcement) => {
    setSelectedAnnouncement(announcement);
    setShowModal(true);
  };

  const confirmToggleActive = async () => {
    try {
      await axios.put(
        `https://kidpedia.net/api/announcements/${selectedAnnouncement.id}`,
        {
          ...selectedAnnouncement,
          isActive: selectedAnnouncement.isActive ? 0 : 1,
          ucad: currentUser.cat,
          headerduyuru: selectedAnnouncement.text, // Add this line to preserve the header text
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      fetchAnnouncements();
      setShowModal(false);
      setSelectedAnnouncement(null);
    } catch (err) {
      console.log(err);
    }
  };

  const handleToggleHeader = (announcement) => {
    setSelectedAnnouncement(announcement);
    setShowHeaderModal(true);
  };

  const confirmToggleHeader = async () => {
    try {
      await axios.put(
        `https://kidpedia.net/api/announcements/${selectedAnnouncement.id}`,
        {
          ...selectedAnnouncement,
          isHeader: selectedAnnouncement.isHeader ? 0 : 1,
          ucad: currentUser.cat,
          headerduyuru: selectedAnnouncement.text, // Preserve the text
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      fetchAnnouncements();
      setShowHeaderModal(false);
      setSelectedAnnouncement(null);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = (announcement) => {
    setAnnouncementToDelete(announcement);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`https://kidpedia.net/api/announcements/${announcementToDelete.id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: { ucad: currentUser.cat },
      });
      fetchAnnouncements();
      setShowDeleteModal(false);
      setAnnouncementToDelete(null);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <AdminHeader />
      <h2>Duyuruları Yönet</h2>
      <Form>
        <Form.Group as={Row} controlId="formImage">
          <Form.Label column sm={2}>
            Resim (1304x50px)
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formLink">
          <Form.Label column sm={2}>
            Bağlantı
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              name="link"
              value={newAnnouncement.link}
              onChange={handleInputChange}
              placeholder="Bağlantı URL'sini girin"
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formIsActive">
          <Form.Label column sm={2}>
            Aktif
          </Form.Label>
          <Col sm={10}>
            <Form.Check
              type="checkbox"
              name="isActive"
              checked={newAnnouncement.isActive}
              onChange={handleInputChange}
              label="Duyuru Aktif"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formIsActive">
          <Form.Label column sm={2}>
            Header Duyuru
          </Form.Label>
          <Col sm={10}>
            <Form.Check
              type="checkbox"
              name="isHeader"
              checked={newAnnouncement.isHeader}
              onChange={handleInputChange}
              label="Header Duyurusu"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formLink">
          <Form.Label column sm={2}>
            Header Duyuru Metini
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              name="text"
              value={newAnnouncement.text}
              onChange={handleInputChange}
              placeholder="Eğer header duyuru ise metin girin"
            />
          </Col>
        </Form.Group>

        <Button
          variant="primary"
          onClick={handleAddAnnouncement}
          disabled={!file && !newAnnouncement.text}
        >
          Duyuru Ekle
        </Button>
      </Form>

      <h3>Mevcut Duyurular</h3>
      <Row className="mt-3">
        {announcements.map((announcement, index) => (
          <Card key={index} className="mb-2 w-100">
            <Card.Body>
              <Row className="align-items-center">
                <Col sm={3}>
                  <Card.Img
                    src={`https://kidpedia.net/upload/${announcement.image}`}
                    alt={`Duyuru ${index + 1}`}
                    style={{ height: "50px", objectFit: "contain" }}
                  />
                </Col>
                <Col sm={3}>
                  <Card.Title className="mb-0">
                    <a
                      href={announcement.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {announcement.link}
                    </a>
                  </Card.Title>
                </Col>
                <Col sm={1}>
                  <Form.Check
                    type="checkbox"
                    checked={announcement.isActive}
                    onChange={() => handleToggleActive(announcement)}
                    label="Duyuru Aktif"
                  />
                </Col>
                <Col sm={3}>
                  <Card.Text className="mb-0">
                    {announcement.text}
                  </Card.Text>
                </Col>
                <Col sm={1}>
                  <Form.Check
                    type="checkbox"
                    checked={announcement.isHeader}
                    onChange={() => handleToggleHeader(announcement)}
                    label="Header Duyurusu"
                  />
                </Col>
                <Col sm={1}>
                  <RiDeleteBin7Fill
                    color="red"
                    size={20}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleDelete(announcement)}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))}
      </Row>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Duyuru Durumunu Değiştir</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Duyuru {selectedAnnouncement?.isActive ? "pasif" : "aktif"} hale getirilecek. Onaylıyor musunuz?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            İptal
          </Button>
          <Button variant="primary" onClick={confirmToggleActive}>
            Onayla
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showHeaderModal} onHide={() => setShowHeaderModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Header Durumunu Değiştir</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Duyuru {selectedAnnouncement?.isHeader ? "header değil" : "header"} hale getirilecek. Onaylıyor musunuz?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowHeaderModal(false)}>
            İptal
          </Button>
          <Button variant="primary" onClick={confirmToggleHeader}>
            Onayla
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Duyuru Sil</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Bu duyuruyu silmek istediğinizden emin misiniz?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            İptal
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Sil
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ManageAnnouncements;
