import React, { useEffect, useState } from 'react';
import { Card, Badge, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';

const LatestQuestions = () => {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('https://kidpedia.net/api/questions')
      .then(response => response.json())
      .then(data => {
        // Filter questions to get only those with answers and sort by the latest answered
        const answeredQuestions = data.filter(q => q.answers && q.answers.length > 0)
                                      .sort((a, b) => new Date(b.answers[0].created_at) - new Date(a.answers[0].created_at));
        setQuestions(answeredQuestions);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching questions:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="text-center py-3">
        <FaSpinner className="me-2 spinner" style={{ fontSize: '1.5rem' }} /> 
        Yükleniyor...
      </div>
    );
  }

  return (
    <div className="latest-questions">
      {questions.map(question => (
        <Card key={question.id} className="mb-3 shadow-sm">
          <Card.Body>
            <Card.Title>
              <Badge bg="secondary" className="me-2">
                {question.category}
              </Badge>
              <Link 
                to={`/sorucevap/${question.id}`} 
                style={{ 
                  textDecoration: 'none', 
                  color: 'inherit',
                  fontSize: '0.9rem'
                }}
              >
                {question.question}
              </Link>
            </Card.Title>
            <Card.Subtitle className="mb-2 text-muted" style={{ fontSize: '0.8rem' }}>
              {new Date(question.created_at).toLocaleDateString()}
            </Card.Subtitle>
            
            {question.answers && question.answers.length > 0 && (
              <ListGroup variant="flush" className="mt-2">
                <ListGroup.Item className="py-2" style={{ fontSize: '0.85rem' }}>
                  {question.answers[0].answer.substring(0, 100)}
                  {question.answers[0].answer.length > 100 && '...'}
                </ListGroup.Item>
              </ListGroup>
            )}
            
            <div className="mt-2">
              <Badge bg="info" className="me-2">
                👍 {question.votes || 0}
              </Badge>
              <Badge bg="light" text="dark">
                💬 {question.answers?.length || 0}
              </Badge>
            </div>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

export default LatestQuestions;
