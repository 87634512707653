import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Pagination,
  FormSelect,
} from "react-bootstrap";
import Posts from "../components/Posts";
import AdminHeader from "../components/Admin/AdminHeader";
import DOMPurify from "dompurify";
import { RiEdit2Fill, RiDeleteBin7Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Update from "./Update";
import { Route } from "react-router-dom";

const PAGE_SIZE = 5;

const AdminPost = () => {
  const [posts, setPosts] = useState([]);
  const [category, setCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`https://kidpedia.net/api/posts`);
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);
  const refreshPage = () => {
    navigate(0);
  };

  const handleDelete = async (postId) => {
    try {
      await axios.delete(`https://kidpedia.net/api/posts/${postId}`);
      refreshPage();
    } catch (err) {
      console.log(err);
      console.log(postId);
    }
  };

  const filteredPosts = category
    ? posts.filter((post) => post.cat === category)
    : posts;

  const totalPages = Math.ceil(filteredPosts.length / PAGE_SIZE);
  const visiblePosts = filteredPosts.slice(
    (currentPage - 1) * PAGE_SIZE,
    currentPage * PAGE_SIZE
  );

  return (
    <>
      <AdminHeader />
      <Container>
        <Row>
          <label>Kategorilere göre Filtrele:</label>
          <FormSelect
            className="mb-3"
            onChange={(event) => setCategory(event.target.value)}
          >
            <option value="">All</option>
            <option value="akademik">Akademik</option>
            <option value="erkencocukluk">Erken Cocukluk</option>
            <option value="aileegitimi">Aile Eğitimi</option>
            <option value="gelisimselaksamalar">Gelişimsel Aksamalar</option>
            <option value="davranisproblemleri">Davranış, Uyum ve İletişim Problemleri</option>
            <option value="ogrenmesorunlari">Öğrenme Sorunları</option>
          </FormSelect>
        </Row>
        <Row>
          {visiblePosts.map((post) => (
            <>
              <Container>
                <Card className="mb-4">
                  <Row className="no-gutters">
                    <Col md={2} lg={2}>
                      <Card.Img
                        className="p-2"
                        variant="top"
                        src={`https://kidpedia.net/upload/${post?.img}`}
                      />
                    </Col>
                    <Col>
                      <Card.Body>
                        <Card.Title>{post.title}</Card.Title>
                        <Card.Text>
                          {/* Bu kısımda hem html olorak dompurify ile yorumlattık hemde koşul ekleyerek 100 
                    karakterden fazla ise o kısmı kestik ve ... ekleyerek devamını okumaya yönlendirdik. */}
                          <Row>
                            <Col
                              sm={10}
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  post.desc.length > 100
                                    ? post.desc.substring(0, 103) + "..."
                                    : post.desc
                                ),
                              }}
                            ></Col>{" "}
                            <Col sm={2} className="d-flex align-middle">
                              {" "}
                              {post.date.substring(0, 10)}
                              <RiEdit2Fill
                                onClick={() => navigate(`/update/${post.id}`)}
                                style={{
                                  height: "2rem",
                                  width: "2rem",
                                  color: "black",
                                }}
                              />
                              <RiDeleteBin7Fill
                                onClick={() => handleDelete(post.id)}
                                style={{
                                  height: "2rem",
                                  width: "2rem",
                                  color: "black",
                                }}
                              />
                            </Col>
                          </Row>
                        </Card.Text>
                        <Button
                          variant="primary"
                          href={`/posts/${post.cat}/${post.id}/${post.title}`}
                          >
                          Devamını Oku
                        </Button>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              </Container>
            </>
          ))}
        </Row>

        <div>
          <Pagination>
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                key={index}
                onClick={() => setCurrentPage(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </div>
      </Container>
    </>
  );
};

export default AdminPost;
