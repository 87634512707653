import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";

// Simple fade-in animation variant
const fadeIn = {
	hidden: { opacity: 0 },
	visible: { opacity: 1, transition: { duration: 0.5 } }
};

// Introduction Section Component with animation
const IntroductionSection = () => (
  <motion.div initial="hidden" animate="visible" variants={fadeIn}>
    <Row className="mb-5">
      <Col>
        <h1 className="text-center mb-4">Hakkımızda</h1>
        <p className="lead text-center">
          Çocukların gelişimini desteklemek ve özel eğitim ihtiyacı olan bireylere kaliteli kaynaklar sunmak amacıyla kurulan sitemiz, aileler, eğitimciler ve uzmanlar için güvenilir bir platform olmayı hedefliyor.
          Sitemizde ücretsiz olarak soru sorabilir, alanında uzman isimlerin makalelerini okuyabilir ve çocukların gelişimine katkı sağlayacak etkinliklere ulaşabilirsiniz.
          Amacımız, bilimsel temelli yaklaşımları pratik çözümlerle birleştirerek her çocuğun potansiyelini ortaya çıkarmasına destek olmaktır.
        </p>
      </Col>
    </Row>
  </motion.div>
);

// Updated Founder Card Component with centered image and justified description text
const FounderCard = ({ imgSrc, imgAlt, name, description }) => (
  <Col lg={6}>
    <motion.div whileHover={{ scale: 1.02 }} initial="hidden" animate="visible" variants={fadeIn}>
      <Card className="h-100 shadow-sm text-center">
        <Card.Img 
          variant="top"
          src={imgSrc}
          className="img-fluid object-fit-cover d-block mx-auto"
          alt={imgAlt}
          style={{ width: "300px", height: "300px", margin: "0.5rem" }}
        />
        <Card.Body>
          <Card.Title>{name}</Card.Title>
          <Card.Text style={{ textAlign: "justify" }}>{description}</Card.Text>
        </Card.Body>
      </Card>
    </motion.div>
  </Col>
);

// Founders Section Component with updated image URLs
const FoundersSection = () => (
  <>
    <h2 className="text-center mb-4">Kurucularımız</h2>
    <Row className="mb-5 gy-4">
      <FounderCard
        imgSrc="/upload/yesimfazlioglu.webp"
        imgAlt="Prof. Dr. Yeşim Fazlıoğlu"
        name="Prof. Dr. Yeşim Fazlıoğlu"
        description="Prof. Dr. Yeşim Fazlıoğlu, çocuk gelişimi ve özel eğitim alanında Türkiye’nin önde gelen isimlerinden biridir.
          Hacettepe, İstanbul ve Ankara Üniversitelerinde tamamladığı lisans, yüksek lisans ve doktora eğitiminin ardından,
          Patras Üniversitesi’nde (Yunanistan) post-doktora çalışmalarını yürütmüştür.
          Erken çocukluk eğitimi, otizm spektrum bozukluğu (OSB) olan çocukların eğitimi, duyusal yaklaşımlar ve çok kültürlülük konularında uzmanlaşan Fazlıoğlu,
          ulusal ve uluslararası düzeyde 20’den fazla akademik makale, kitap bölümü ve projeye imza atmıştır.
          Montessori metodunun özel eğitimdeki uygulamaları, OSB’li çocukların sosyal becerilerinin geliştirilmesi ve aile destek programları üzerine çalışmalarıyla tanınmaktadır.
          Aynı zamanda Trakya Üniversitesi’nde 'Çocuk Ruh Sağlığı', 'Otizm' ve 'Montessori Eğitimi' gibi dersler vermekte, öğretmen adaylarına ve ailelere rehberlik etmektedir."
      />
      <FounderCard
        imgSrc="/upload/mustafaozaydin.webp"
        imgAlt="M. Mustafa Özaydın"
        name="M. Mustafa Özaydın"
        description="M. Mustafa Özaydın, 13 yıllık özel eğitim öğretmenliği deneyimiyle alanın sahada yetişmiş bir uzmanıdır.
          Rehabilitasyon merkezleri, MEB Rehberlik Araştırma Merkezleri ve özel eğitim okullarında görev alarak yüzlerce çocuğun gelişimine katkı sağlamıştır.
          Eskişehir Osmangazi Üniversitesi ve Anadolu Üniversitesi’nin ortak programında 'özel eğitimde okuduğunu anlama becerilerinin geliştirilmesi' konulu yüksek lisansını tamamlamıştır.
          Doktora çalışmalarını ise Trakya Üniversitesi Engelli Çalışmaları Programı’nda sürdüren Özaydın, 'teknoloji destekli matematik eğitimi' üzerine odaklanmaktadır.
          Sanal manipülatifler kullanarak toplama işlemi öğretimi konusunda yenilikçi yöntemler geliştirmekte ve dijital araçların özel eğitimdeki rolünü genişletmeyi amaçlamaktadır.
          Hem akademik hem de pratik bilgisini birleştirerek, sitemizde sunulan etkinliklerin ve içeriklerin teknolojiyle entegre edilmesine öncülük etmektedir."
      />
    </Row>
  </>
);

// Mission Section Component with animation
const MissionSection = () => (
  <motion.div initial="hidden" animate="visible" variants={fadeIn}>
    <Row className="bg-light rounded-3 p-4 mt-5">
      <Col>
        <h2 className="text-center mb-4">Misyonumuz</h2>
        <p className="text-center fs-5">
          Çocukların bireysel ihtiyaçlarına odaklanarak, bilimsel ve yenilikçi yaklaşımlarla
          herkes için erişilebilir bir eğitim platformu sunmak.
        </p>
      </Col>
    </Row>
  </motion.div>
);

const Uzmanlar = () => {
  return (
    <Container className="py-5">
      <Helmet>
        <title>Hakkımızda</title>
      </Helmet>
      <IntroductionSection />
      <FoundersSection />
      <MissionSection />
    </Container>
  );
};

export default Uzmanlar;
