import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Pagination,
  FormSelect,
} from "react-bootstrap";
import AdminHeader from "../components/Admin/AdminHeader";
import DOMPurify from "dompurify";
import { RiEdit2Fill, RiDeleteBin7Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import { ToastContainer, toast } from "react-toastify";

const PAGE_SIZE = 5;

const AdminEgitim = () => {
  const { currentUser } = useContext(AuthContext);

  const [posts, setPosts] = useState([]);
  const [category, setCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [userpost, setUserpost] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`https://kidpedia.net/api/egitim`);
        setPosts(res.data);
      } catch (err) {
        toast.error("Postlar yüklenmedi");
        console.log(err);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`https://kidpedia.net/api/users/users`);
        setUsers(res.data);
      } catch (err) {
        toast.error("Userlar yüklenmedi");
        console.log(err);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`https://kidpedia.net/api/egitim/user`);
        setUserpost(res.data);
        console.log(res.data);
      } catch (err) {
        toast.error("Posta atanan kişiler yüklenmedi");
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const refreshPage = () => {
    navigate(0);
  };

  const handleDelete = async (postId) => {
    try {
      await axios.delete(`https://kidpedia.net/api/egitim/${postId}`);
      refreshPage();
    } catch (err) {
      console.log(err);
    }
  };
  const handleDeleteUser = async (egitimUserID) => {
    try {
      await axios.delete(
        `https://kidpedia.net/api/egitim/userdelete/${egitimUserID}`
      );
      refreshPage();
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setSelectedId(value);
  };

  const saveUserSelection = async (postId) => {
    try {
      const response = await axios.post(`https://kidpedia.net/api/users`, {
        selectedId,
        postId,
      });
      toast.success("User selection saved successfully!");

      // Fetch the updated userpost data from the server
      const updatedUserpost = await axios.get(
        `https://kidpedia.net/api/egitim/user`
      );
      setUserpost(updatedUserpost.data);
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong. Please try again.");
    }
  };

  const filteredPosts = category
    ? posts.filter((post) => post.cat === category)
    : posts;

  const totalPages = Math.ceil(filteredPosts.length / PAGE_SIZE);
  const visiblePosts = filteredPosts.slice(
    (currentPage - 1) * PAGE_SIZE,
    currentPage * PAGE_SIZE
  );
  console.log(userpost);
  const getAssignedUsers = (postId) => {
    console.log(postId);
    return userpost.filter((user) => user.id === postId);
  };

  console.log(getAssignedUsers);
  if (currentUser !== null) {
    return (
      <>
        <AdminHeader />
        <Container>
          <ToastContainer />

          <Row>
            <label>Kategorilere göre Filtrele:</label>
            <FormSelect
              className="mb-3"
              onChange={(event) => setCategory(event.target.value)}
            >
              <option value="">All</option>
              <option value="akademik">Akademik</option>
              <option value="erkencocukluk">Erken Cocukluk</option>
              <option value="aileegitimi">Aile Eğitimi</option>
              <option value="gelisimselaksamalar">Gelişimsel Aksamalar</option>
              <option value="davranisproblemleri">
                Davranış, Uyum ve İletişim Problemleri
              </option>
              <option value="ogrenmesorunlari">Öğrenme Sorunları</option>
            </FormSelect>
          </Row>
          <Row>
            {visiblePosts.map((post) => (
              <Container key={post.id}>
                <Card className="mb-4">
                  <Row className="no-gutters">
                    <Col md={2} lg={2}>
                      <Card.Img
                        className="p-2"
                        variant="top"
                        src={`https://kidpedia.net/upload/${post?.img}`}
                      />
                    </Col>
                    <Col>
                      <Card.Body>
                        <Card.Title>{post.title}</Card.Title>
                        <Card.Text>
                          <Row>
                            <Col
                              sm={10}
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  post.desc.length > 100
                                    ? post.desc.substring(0, 103) + "..."
                                    : post.desc
                                ),
                              }}
                            ></Col>
                            <Col sm={2} className="d-flex align-middle">
                              {post.date.substring(0, 10)}
                              <RiEdit2Fill
                                onClick={() => navigate(`/egitimupdate/${post.id}`)}
                                style={{
                                  height: "2rem",
                                  width: "2rem",
                                  color: "black",
                                }}
                              />
                              <RiDeleteBin7Fill
                                onClick={() => handleDelete(post.id)}
                                style={{
                                  height: "2rem",
                                  width: "2rem",
                                  color: "black",
                                }}
                              />
                            </Col>
                          </Row>
                        </Card.Text>
                        <Button
                          variant="primary"
                          href={`/egitim/${post.cat}/${post.id}`}
                        >
                          Devamını Oku
                        </Button>
                        <Row className="mt-2">
                          <Col md={6}>
                            <select
                              className="form-select"
                              size="3"
                              aria-label="size 3 select example"
                              onChange={handleChange}
                            >
                              {users.map((user) => (
                                <option key={user.ID} value={user.ID}>
                                  {user.firstname} {user.lastname}
                                </option>
                              ))}
                            </select>
                          </Col>
                          <Col md={6}>
                            <div className="user-box">
                              <h5>Atanan Kişiler</h5>
                              <ul className="user-list">
                                {getAssignedUsers(post.id).map(
                                  (assignedUser) => (
                                    <React.Fragment key={assignedUser.user_id}>
                                      <li>
                                        {assignedUser.firstname}{" "}
                                        {assignedUser.lastname}
                                        <RiDeleteBin7Fill
                                          className="pr-2"
                                          onClick={() =>
                                            handleDeleteUser(
                                              assignedUser.egitimuser_id
                                            )
                                          }
                                          style={{
                                            height: "1rem",
                                            width: "1rem",
                                            color: "blue",
                                          }}
                                        />
                                      </li>
                                    </React.Fragment>
                                  )
                                )}
                              </ul>
                            </div>
                          </Col>
                          <Col md={4}>
                            <Button
                              onClick={() => saveUserSelection(post.id)}
                              variant="danger"
                            >
                              Kaydet
                            </Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              </Container>
            ))}
          </Row>

          <div>
            <Pagination>
              {Array.from({ length: totalPages }, (_, index) => (
                <Pagination.Item
                  key={index}
                  onClick={() => setCurrentPage(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>
        </Container>
      </>
    );
  } else {
    return (
      <>
        <h1>Bu sayfayı görmeye yetkiniz yok.</h1>
      </>
    );
  }
};

export default AdminEgitim;
