import React from "react";
import Body from "../components/Body"
import Helmet from "react-helmet";
const Anasayfa = () => {
  return (



 <>
      <Helmet>
        <title>Kidpedia </title>
        <meta name="description" content="Otizm" />{" "}
        <meta name="description" content="Öğrenme Güçlüğü" />
        <meta name="description" content="Gelişim Geriliği" />
        <meta name="description" content="Disleksi" />
        <meta name="description" content="Diskalkuli" />
        <meta name="description" content="Dil ve Konuşma Güçlüğü" />
        <meta name="description" content="Zihin Yetersizliği" />
        <meta name="description" content="çocuk gelişimi" />
        <meta name="description" content="çocuk egitimi" /> 
        <meta name="description" content="erken cocukluk" />
        <meta name="description" content="ergenlik donemi" />
        <meta name="description" content="akademik egitim" />
        <meta name="description" content="aile egitimi" />
        <meta name="description" content="gelisimsel aksamalar" />
        <meta name="description" content="Cinsel Eğitim" />
        <meta name="description" content="problem davranışlar" />
      </Helmet>
      <Body />
    </>
    );
};

export default Anasayfa;
