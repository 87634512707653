import React, { useState, useEffect } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { X, ChevronRight } from 'lucide-react';

const AnnouncementBanner = ({ 
  announcementId, // new prop for unique announcement id
  message = "Kidpedia'ya hoşgeldiniz!", 
  link = "/" 
}) => {
  // When the announcementId changes, show banner unless it was already closed.
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const closedId = localStorage.getItem("announcementClosedId");
    if (announcementId && closedId === String(announcementId)) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  }, [announcementId]);

  if (!visible) return null;

  const handleClick = () => {
    window.open(link, '_blank'); // updated to open in a new tab
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setVisible(false);
    if (announcementId) localStorage.setItem("announcementClosedId", announcementId);
  };

  return (
    <Alert 
      variant="info" 
      onClick={handleClick} 
      style={{ cursor: 'pointer', position: 'relative', marginBottom: '1rem' }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>{message}</span>
        <div>
          <ChevronRight size={16} />
          <Button 
            variant="link" 
            onClick={handleClose}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <X size={16} />
          </Button>
        </div>
      </div>
    </Alert>
  );
};

export default AnnouncementBanner;
