import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import EventSidebar from "../components/EventSidebar";
import EventTitles from "../components/EventTitles";
import Helmet from "react-helmet";
const Articles = () => {
  return (
    <>
    <Helmet>
    <title>Etkinlikler</title>
    <meta name="description" content="Otizm" />{" "}
      <meta name="description" content="Öğrenme Güçlüğü" />
      <meta name="description" content="Gelişim Geriliği" />
      <meta name="description" content="Disleksi" />
      <meta name="description" content="Diskalkuli" />
      <meta name="description" content="Dil ve Konuşma Güçlüğü" />
      <meta name="description" content="Zihin Yetersizliği" />
  </Helmet>
      <Container>
        <Row>
          <Col md={3} className="border-end">
            {" "}
            <EventSidebar />
          </Col>
          <Col md={9}>
            {" "}
            <EventTitles />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Articles;
