import React, { useEffect, useState, useContext } from "react";
import { useLocation, Link } from "react-router-dom";
import { Navbar, Container, Nav, NavbarBrand, NavLink, NavItem, Button } from "react-bootstrap";
import bootstaplogo from "../asset/logo.png";
import { RiMailSendLine, RiLoginBoxFill, RiPencilFill, RiLogoutBoxFill } from "react-icons/ri";
import { AuthContext } from "../context/authContext";
import Cart from "./Cart";
import axios from "axios";
import AnnouncementBanner from "./AnnouncementBanner";

const Header = () => {
  const location = useLocation();
  const [url, setUrl] = useState(null);
  const [announcement, setAnnouncement] = useState(null);
  const { currentUser, logout } = useContext(AuthContext);

  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  useEffect(() => {
    axios.get("https://kidpedia.net/api/announcements/header")
      .then((res) => {
        if (res.data && res.data.length > 0) {
          setAnnouncement(res.data[0]);
        }
      })
      .catch((err) => {
        console.error("Error fetching header announcement:", err);
      });
  }, []);

  return (
    <div>
      {announcement && (
        <AnnouncementBanner 
          announcementId={announcement.id}  // pass new prop for unique id
          message={announcement.text} 
          link={announcement.link} 
        />
      )}
      <Container>
        <Navbar href="/" bg="light" expand="md" fixed="top" className="mb-4 border-bottom shadow-sm">
          <NavbarBrand href="/" className="ps-5 d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
            <img alt="kidpedia" className="img-fluid" width="150" height="auto" src={bootstaplogo} />
          </NavbarBrand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav className="d-flex align-items-center">
              <NavItem className="mx-2">
                <NavLink className={`nav-link${url === "/" ? " active" : ""}`} href="/">
                  AnaSayfa
                </NavLink>
              </NavItem>
              <NavItem className="mx-2">
                <NavLink className={`nav-link${url === "/posts" ? " active" : ""}`} href="/posts/">
                  Makaleler
                </NavLink>
              </NavItem>
              <NavItem className="mx-2">
                <NavLink className={`nav-link${url === "/etkinlikler" ? " active" : ""}`} href="/etkinlikler/">
                  Etkinlikler
                </NavLink>
              </NavItem>
             {/*  <NavItem className="mx-2">
                <NavLink className={`nav-link${url === "/randevu" ? " active" : ""}`} href="/randevu">
                  Randevu
                </NavLink> 
              </NavItem>*/}
             
              {currentUser ? (
                <NavItem className="mx-2">
                  <NavLink className={`nav-link${url === "/profile" ? " active" : ""}`} href="/profile">
                    Profile
                  </NavLink>
                </NavItem>
              ) : null}
              {/* <NavItem className="mx-2">
                <NavLink className={`nav-link${url === "/kurumsal" ? " active" : ""}`} href="/kurumsal">
                  Kurumsal
                </NavLink>
              </NavItem> */}
              <NavItem className="mx-2">
                <NavLink className={`nav-link${url === "/sorucevap" ? " active" : ""}`} href="/sorucevap">
                  Sorucevap
                </NavLink>
              </NavItem>
              <NavItem className="mx-2">
                <NavLink className={`nav-link${url === "/hakkimizda" ? " active" : ""}`} href="/hakkimizda">
                  Hakkımızda
                </NavLink>
              </NavItem>
              {currentUser ? (
                currentUser.cat === 503450 ? (
                  <NavItem className="mx-2">
                    <NavLink className={`nav-link${url === "/admin" ? " active" : ""}`} href="/admin">
                      Yönetim
                    </NavLink>
                  </NavItem>
                ) : null
              ) : null}
            </Nav>
            {currentUser ? (
              <NavItem className="font-weight-bold text-capitalize p-1 mx-2">
                Hoşgeldin {currentUser.firstname + " " + currentUser.lastname}
              </NavItem>
            ) : null}
            {currentUser ? (
              <>
                <Button variant="outline-danger" onClick={logout} className="px-2 me-2">
                  <RiLogoutBoxFill /> Çıkış
                </Button>
                <Cart />
              </>
            ) : (
              <>
                <Button variant="outline-success" className="px-2 me-2" href="/register">
                  <RiPencilFill /> Kayıt Ol
                </Button>
                <Button variant="outline-primary" className="px-2 me-2" href="/login">
                  <RiLoginBoxFill /> Giriş
                </Button>
              </>
            )}
            <Button variant="outline-warning" href="/iletisim" className="px-3 me-2">
              <RiMailSendLine /> İletişim
            </Button>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </div>
  );
};

export default Header;
