import React, { useState, useContext, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { AuthContext } from "../context/authContext";
import moment from "moment";
import AdminHeader from "../components/Admin/AdminHeader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EventUpdate = () => {
  const { currentUser } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const state = location.state;
  const [value, setValue] = useState(state?.desc || "");
  const [title, setTitle] = useState(state?.title || "");
  const [file, setFile] = useState(null);
  const [cat, setCat] = useState(state?.cat || "");
  const [img, setImg] = useState(state?.img);
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfControl, setPdfControl] = useState(state?.pdfurls || "");

  const navigate = useNavigate();

  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await axios.post("https://kidpedia.net/api/upload", formData); // Ensure this endpoint matches the server route
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const uploadPdf = async () => {
    try {
      const formData = new FormData();
      formData.append("pdf", pdfFile);
      const res = await axios.post("https://kidpedia.net/api/upload-pdf", formData);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  console.log(id);

  useEffect(() => {
    axios
      .get(`https://kidpedia.net/api/events/${id}`)
      .then((response) => {
        setTitle(response.data.title);
        setValue(response.data.desc);
        setFile(response.data.img);
        setCat(response.data.cat);
        setImg(response.data.img);
        setPdfControl(response.data.pdfurls);
        setPdfFile(response.data.pdfurls);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

     const refreshPage = () => {
       setTimeout(() => {
         navigate(0);
       }, 3000);
     };

  const handleClick = async (e) => {
    e.preventDefault();
    let fileUrl = img;  // Default to existing image
    let pdfFileurl = pdfControl;  // Default to existing PDF

    if (file) {  // Only upload if new image file is selected
      const uploadedFile = await upload();
      if (uploadedFile) fileUrl = uploadedFile;
    }

    if (pdfFile) {  // Only upload if new PDF file is selected
      const uploadedPdf = await uploadPdf();
      if (uploadedPdf) pdfFileurl = uploadedPdf;
    }

    try {
      await axios.put(`https://kidpedia.net/api/events/${id}`, {
        title,
        desc: value,
        cat,
        img: fileUrl,
        date: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
        uid: currentUser.ID,
        ucad: currentUser.cat,
        pdfurls: pdfFileurl,
      });
      toast.success('Başarıyla güncellendi!');
      refreshPage();
    } catch (err) {
      toast.error('Güncelleme başarısız!');
      console.log(err);
    }
  };

  // Admin access check
  switch (currentUser?.cat) {
    case 503450:
    case 503422:
      return (
        <>
          <AdminHeader />
          <ToastContainer position="top-right" autoClose={3000} />
          <Container>
            <Form>
              <Row>
                <Col sm={9}>
                  <input
                    type="text"
                    placeholder="Başlık"
                    className="form-control"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                  />
                  <ReactQuill
                    theme="snow"
                    value={value}
                    onChange={setValue}
                    className="h-75"
                  />
                </Col>
                <Col sm={3}>
                  <div className="menu">
                    <div className="item">
                      <h4>Güncelle</h4>
                      <Form.Group
                        controlId="formFile"
                        className="mb-2"
                        onChange={(e) => setFile(e.target.files[0])}
                      >
                        <Form.Label className="mt-2 text-end fw-bold">
                          Resim Yükle
                        </Form.Label>
                        <Form.Control type="file" />
                      </Form.Group>
                      <Form.Group
                        controlId="formPdfFile"
                        className="mb-2"
                        onChange={(e) => setPdfFile(e.target.files[0])}
                      >
                        <Form.Label className="mt-2 text-end fw-bold">
                          PDF Dosyası Yükle
                        </Form.Label>
                        <Form.Control type="file" accept=".pdf" />
                      </Form.Group>
                      <div className="buttons">
                        <Button variant="success" onClick={handleClick}>
                          Güncelle
                        </Button>
                      </div>
                    </div>
                    <div className="item">
                      <h4>Kategoriler</h4>
                      <div className="cat">
                        {[
                          "akademik",
                         "erkencocukluk",
                         "aileegitimi",
                         "gelisimselaksamalar",
                         "davranisproblemleri",
                         "ogrenmesorunlari",
                        ].map((category) => (
                          <div key={category}>
                            <input
                              type="radio"
                              name="cat"
                              value={category}
                              id={category}
                              onChange={(e) => setCat(e.target.value)}
                              checked={cat === category}
                            />
                            <label htmlFor={category}>{category}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                {`Yüklenmiş dosya: ${pdfFile}`}
              </Row>
            </Form>
          </Container>
        </>
      );
    case 503439:
      return <div>BackendUser</div>;
    default:
      return <div>Bu sayfaya erişim hakkınız yok.</div>;
  }
};

export default EventUpdate;
