import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Pagination} from "react-bootstrap"
import Posts from '../components/Posts'

const Post = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`https://kidpedia.net/api/events`);
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);


 
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
 
  

  const pageNumbers =  []


  for (let i = 1; i <= Math.ceil(posts.length/ postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = pageNumbers => setCurrentPage(pageNumbers);



  return (
    <>
  


         <Posts posts={currentPosts} loading={loading} />






         <nav>
      <ul className='pagination'>
        {pageNumbers.map(number => (
         <Pagination>
          <Pagination.Item className={currentPage === number ? "active" : null} key={number} onClick={() => paginate(number)} active={
                pageNumbers === {number} ? true : false
              }>{number}</Pagination.Item>
         </Pagination>
         
         
         
         
        
        ))}
      </ul>
    </nav>
</>

);

      
};

export default Post;