import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Button, Card, Col, Container, Row,Pagination,Stack} from "react-bootstrap"
import Posts from '../components/Posts'
import { useLocation,useParams } from 'react-router-dom';

const Post = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);
  const [showedData, showData] = useState(posts.slice(0, postsPerPage));
  let { cat } = useParams();
  
  useEffect(() => {
    // Fetch the list of posts from an API, filtered by category
    fetch(`https://kidpedia.net/api/events?cat=${cat}`)
      .then(response => response.json())
      .then(posts => setPosts(posts))
      .catch(error => console.error(error));
  }, [cat]);


 
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
 
  

  const pageNumbers =  []


  for (let i = 1; i <= Math.ceil(posts.length/ postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = pageNumbers => setCurrentPage(pageNumbers);

  
  const [selectedCategory, setSelectedCategory] = useState('all');

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const filteredPosts = selectedCategory === 'all' ? posts : posts.filter(post => post.category === selectedCategory);

  return (
    <>
  


         <Posts posts={currentPosts} loading={loading} />






         <nav>
      <ul className='pagination'>
        {pageNumbers.map(number => (
         <Pagination>
          <Pagination.Item className={currentPage == number ? "active" : null} key={number} onClick={() => paginate(number)} active={
                pageNumbers === {number} ? true : false
              }>{number}</Pagination.Item>
         </Pagination>
         
         
         
         
        //  <li key={number} active={
        //     currentPage === {number} ? true : false
        //   } className='page-item'>
        //     <a onClick={() => paginate(number)}  className='page-link'>
        //       {number}
        //     </a>
        //   </li>
        ))}
      </ul>
    </nav>
</>

);

      
};

export default Post;