import "./App.css";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Admin from "./pages/Admin";
import Anasayfa from "./pages/Anasayfa";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Single from "./pages/Single";
import Write from "./pages/Write";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ErrorPage from "./pages/error-page";
import Calender from "./pages/Calender";
import Profile from "./pages/Profile";
import "bootstrap/dist/css/bootstrap.min.css";
import Uzmanlar from "./pages/Uzmanlar";
import Makaleler from "./pages/Articles";
import Makaleler2 from "./pages/Articles2";
import Events from "./pages/Events";
import Events2 from "./pages/Events2";
import VideoUpload from "./components/VideoUpload";
import EgitimWrite from "./pages/EgitimWrite";
import AdminPost from "./pages/AdminPosts";
import AdminEgitim from "./pages/AdminEgitim";
import SingleEgitim from "./pages/SingleEgitim";
import Update from "./pages/Update";
import AdminFreeGorusme from "./pages/Adminfreegorusme";
import Randevu from "./components/Randevu";
import EgitimOneri from "./components/EgitimOneri";
import AdminEgitimAta from "./pages/AdminEgitimAta";
import Store from "./pages/Store";
import { CartProvider } from "./context/CartContext";
import AdminStore from "./pages/AdminStore";
import Purchase from "./pages/Purchase";
import AdminUcretligorusme from "./pages/AdminUcretliGorusme";
import Contact from "./pages/Contact";
import AdminContact from "./pages/AdminContact";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import Recovery from "./pages/Recovery";
import ResetPassword from "./pages/Resetpassword";
import Dergi from "./pages/Dergi";
import EgitimWriteUpdate from "./pages/EgitimWriteUpdate";
import Kurumsal from "./pages/Kurumsal";
import SingleSirket from "./pages/SingleSirket";
import SingleKurumsalEgitim from "./pages/SinglekurumsalEgitim";
import ReactGA from "react-ga4";
import kucuklogo from "./asset/kucuklogo.png";
import RouteChangeTracker from "./components/RouteChangeTracker";
import Questions from "./pages/Questions";
import QuestionSingle from "./pages/QuestionSingle";
import SingleEvent from "./pages/SingleEvent"; // Import SingleEvent component
import EventWrite from "./pages/EventWrite";
import ManageAnnouncements from "./pages/ManageAnnouncements";
import EventUpdate from "./pages/EventUpdate";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Header />
        <Outlet />
        <Footer />
        <RouteChangeTracker />
      </>
    ),
    errorElement: <ErrorPage />,
    children: [
      /*  {
        path: "/randevu",
        element: <Calender />,
      }, */
      {
        path: "/iletisim",
        element: <Contact />,
      },
      {
        path: "/hakkimizda",
        element: <Uzmanlar />,
      },
      /*  {
        path: "/kurumsal",
        element: <Kurumsal />,
      }, */
      /*   {
        path: "/kurumsal/sirket",
        element: <SingleSirket />,
      }, */
      /*  {
        path: "/kurumsal/egitim",
        element: <SingleKurumsalEgitim />,
      },
      {
        path: "/store",
        element: <Store />,
      }, */
      /*  {
        path: "/dergi",
        element: <Dergi />,
      },
      {
        path: "/purchase",
        element: <Purchase />,
      }, */
      {
        path: "/admin/write",
        element: <Write />,
      },
      {
        path: "/admin/egitimyaz",
        element: <EgitimWrite />,
      },
      {
        path: "/admin/etkinlikyaz",
        element: <EventWrite />,
      },
      {
        path: "/admin/adminposts",
        element: <AdminPost />,
      },
      {
        path: "/admin/ucretsizgorusme",
        element: <AdminFreeGorusme />,
      },
      {
        path: "/admin/ucretligorusme",
        element: <AdminUcretligorusme />,
      },
      {
        path: "/admin/egitim",
        element: <AdminEgitim />,
      },
      {
        path: "/admin/iletisim",
        element: <AdminContact />,
      },
      {
        path: "/admin/kisilereatananegitimler",
        element: <AdminEgitimAta />,
      },
      {
        path: "/admin/usercart",
        element: <AdminStore />,
      },
      {
        path: "/posts/",
        element: <Makaleler />,
      },
      {
        path: "/",
        element: <Anasayfa />,
      },
      {
        path: "/admin",
        element: <Admin />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/recovery",
        element: <Recovery />,
      },
      {
        path: "/resetpassword/:token2",
        element: <ResetPassword />,
      },
      { path: "/etkinlikler", element: <Events /> },
      {
        path: "/etkinlikler/:cat",
        element: <Events2 />,
      },

      {
        path: "/posts/:cat",
        element: <Makaleler2 />,
      },
      {
        path: "/posts/:cat/:id/:title",
        element: <Single />,
      },
      {
        path: "/egitim/:cat/:id",
        element: <SingleEgitim />,
      },
      {
        path: "/etkinlikler/:cat/:id/:title",
        element: <SingleEvent />,
      },
      {
        path: "/admin/duyuru",
        element: <ManageAnnouncements />,
      },
      {
        path: "/write/:id",
        element: <Write />,
      },
      {
        path: "/update/:id",
        element: <Update />,
      },
      {
        path: "/etkinlikupdate/:id",
        element:<EventUpdate/>

      },
      {
        path: "/egitimupdate/:id",
        element: <EgitimWriteUpdate />,
      },
      {
        path: "/sorucevap",
        element: <Questions />,
      },
      {
        path: "/sorucevap/:id",
        element: <QuestionSingle />,
      },
    ],
  },

  {
    path: "/profile",
    element: (
      <>
        <Header />
        <Profile />
        <Footer />
      </>
    ),
    children: [
      {
        path: "video",
        element: <VideoUpload />,
      },
      {
        path: "randevu",
        element: <Randevu />,
      },
      {
        path: "egitimonerileri",
        element: <EgitimOneri />,
      },
    ],
  },
]);

function App() {
  ReactGA.initialize("G-4Y9QGG8TMH");

  return (
    <CartProvider>
      <FloatingWhatsApp
        phoneNumber="+902826060696"
        accountName="Kidpedia"
        avatar={kucuklogo}
        notification
        notificationSound
        statusMessage="Yaklaşık 15 dakika içinde cevap verilir."
        chatMessage="Merhaba. Nasıl yardımcı olabilirim?"
        placeholder="Mesajınızı buraya girin..."
      />

      <div className="app">
        <div className="container">
          <RouterProvider router={router}></RouterProvider>
        </div>
      </div>
    </CartProvider>
  );
}

export default App;
